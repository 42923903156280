define("discourse/plugins/discourse-translator/discourse/initializers/extend-for-translate-button", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/deprecated", "discourse/lib/plugin-api", "discourse-i18n", "discourse/plugins/discourse-translator/discourse/components/language-switcher", "discourse/plugins/discourse-translator/discourse/components/post-menu/toggle-translation-button", "discourse/plugins/discourse-translator/discourse/components/show-original-content", "discourse/plugins/discourse-translator/discourse/components/translated-post"], function (_exports, _ajax, _ajaxError, _deprecated, _pluginApi, _discourseI18n, _languageSwitcher, _toggleTranslationButton, _showOriginalContent, _translatedPost) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeTranslation(api) {
    const siteSettings = api.container.lookup("service:site-settings");
    if (!siteSettings.translator_enabled) {
      return;
    }
    const currentUser = api.getCurrentUser();
    if (!currentUser && siteSettings.experimental_anon_language_switcher) {
      api.headerIcons.add("discourse-translator_language-switcher", _languageSwitcher.default, {
        before: ["search"]
      });
    }
    if (siteSettings.experimental_inline_translation && (currentUser || siteSettings.experimental_anon_language_switcher)) {
      api.renderInOutlet("topic-navigation", _showOriginalContent.default);
    }
    customizePostMenu(api);
  }
  function customizePostMenu(api, container) {
    const transformerRegistered = api.registerValueTransformer("post-menu-buttons", _ref => {
      let {
        value: dag,
        context: {
          firstButtonKey
        }
      } = _ref;
      dag.add("translate", _toggleTranslationButton.default, {
        before: firstButtonKey
      });
    });
    if (transformerRegistered) {
      // the plugin outlet is not updated when the post instance is modified unless we register the new properties as
      // tracked
      api.addTrackedPostProperties("detectedLang", "isTranslating", "isTranslated", "translatedText", "translatedTitle");
      api.renderBeforeWrapperOutlet("post-menu", _translatedPost.default);
    }
    const silencedKey = transformerRegistered && "discourse.post-menu-widget-overrides";
    (0, _deprecated.withSilencedDeprecations)(silencedKey, () => customizeWidgetPostMenu(api, container));
  }
  function customizeWidgetPostMenu(api) {
    api.includePostAttributes("can_translate", "translated_text", "detected_lang", "translated_title");
    const siteSettings = api.container.lookup("service:site-settings");
    api.decorateWidget("post-menu:before", dec => {
      if (!dec.state.isTranslated) {
        return;
      }
      if (dec.state.isTranslating) {
        return dec.h("div.spinner.small");
      }
      const language = dec.attrs.detected_lang;
      const translator = siteSettings.translator_provider;
      let titleElements = [];
      if (dec.attrs.translated_title) {
        titleElements = [dec.h("div.topic-attribution", dec.attrs.translated_title)];
      }
      return dec.h("div.post-translation", [dec.h("hr"), ...titleElements, dec.h("div.post-attribution", (0, _discourseI18n.i18n)("translator.translated_from", {
        language,
        translator
      })), dec.cooked(dec.attrs.translated_text)]);
    });
    api.attachWidgetAction("post-menu", "translate", function () {
      const state = this.state;
      state.isTranslated = true;
      state.isTranslating = true;
      this.scheduleRerender();
      const post = this.findAncestorModel();
      if (post) {
        return (0, _ajax.ajax)("/translator/translate", {
          type: "POST",
          data: {
            post_id: post.get("id")
          }
        }).then(function (res) {
          post.setProperties({
            translated_text: res.translation,
            detected_lang: res.detected_lang,
            translated_title: res.title_translation
          });
        }).catch(error => {
          (0, _ajaxError.popupAjaxError)(error);
          state.isTranslating = false;
          state.isTranslated = false;
        }).finally(() => state.isTranslating = false);
      }
    });
    api.attachWidgetAction("post-menu", "hideTranslation", function () {
      this.state.isTranslated = false;
      const post = this.findAncestorModel();
      if (post) {
        post.set("translated_text", "");
      }
    });
    api.addPostMenuButton("translate", (attrs, state) => {
      if (!attrs.can_translate) {
        return;
      }
      const [action, title] = !state.isTranslated ? ["translate", "translator.view_translation"] : ["hideTranslation", "translator.hide_translation"];
      return {
        action,
        title,
        icon: "globe",
        position: "first",
        className: state.isTranslated ? "translated" : null
      };
    });
  }
  var _default = _exports.default = {
    name: "extend-for-translate-button",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.39.2", api => initializeTranslation(api));
    }
  };
});